import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
  } from 'vuelidate/lib/validators';
  import { mapState } from 'vuex';
  import { DateFormater } from "@/_helpers/funciones";

const isValidFile = (value) => {
    if(!value)
      return false;
  
    if(value.size && Math.round((value.size / 1024)) > 4096){
      return false;
    }
  
    if(value.name){
      let file = value.name.substring(value.name.lastIndexOf('.')+1, value.name.length) || "";
      return ['pdf','doc','docx','xlsx', 'jpg', 'png'].includes(file.toLowerCase());
    }else
      return (value != '');
};

const Time = (value) => /^((?:2[0-3]|[01][0-9]):[0-5][0-9])$/.test(value);

export const FormAllPlanificacion = (value, TpVesselId) => {
    let applyBulkVessel = {};
    let applyTpVesselId = {};
    let applyModality = {};
    if(TpVesselId==process.env.VUE_APP_BULK_VESSEL_ID){
      applyBulkVessel = { required };
      applyModality = { required };
      applyTpVesselId = { required };
    }else if(TpVesselId==process.env.VUE_APP_GENERAL_CARGO_VESSEL_ID){
      applyTpVesselId = { required };
    }
    return {
      formRoutePlanificacion: {
        PlanificacionDs:{
          required,
          minLength: minLength(4),
          maxLength: maxLength(500)
        },
      },
      formPlanning:{
        OperationId:{
          required
        },
        ConditionPlanningId:{
          required
        },
        ModalityId: applyModality,
        TpVesselId: applyTpVesselId,
        TruckWeighingModalityId: applyBulkVessel,
        StowageFactor: applyBulkVessel,
        FreshWater: { },
        Constant: { },
        DieselOil: { },
        FuelOil: { },
        Ballast: { },
      }
    }
}

export const FormAsignGrua = (DateFrom, DateTo, laterDate, previousDate) => {
  return {
    DateFrom: { required, TimeValidate (value){
      return DateFormater.formatDateTimeWithoutSlash(value) >= previousDate &&
        DateFormater.formatDateTimeWithoutSlash(value) <= laterDate
      }, RangeValidate (value) {
        if (DateTo != 'Invalid date') {
          return DateFormater.formatDateTimeWithoutSlash(value) < DateTo
        }else{
          return true
        }  
      } 
    },
    DateTo: { required, TimeValidate (value) {
        return DateFormater.formatDateTimeWithoutSlash(value) <= laterDate &&
        DateFormater.formatDateTimeWithoutSlash(value) >= previousDate
      }, RangeValidate (value) {
        if (DateFrom != 'Invalid date') {
          return DateFormater.formatDateTimeWithoutSlash(value) > DateFrom
        }else{
          return true
        }
      } 
    },
    formAsignGrua: {
      FgAct:{required},
    },
  }
}

export const FormAsignMaquina = (DateFrom, DateTo, laterDate, previousDate) => {
  return {
    DateFrom: { required, TimeValidate (value){
      return DateFormater.formatDateTimeWithoutSlash(value) >= previousDate &&
        DateFormater.formatDateTimeWithoutSlash(value) <= laterDate
      }, RangeValidate (value) {
        if (DateTo != 'Invalid date') {
          return DateFormater.formatDateTimeWithoutSlash(value) < DateTo
        }else{
          return true
        }  
      } 
    },
    DateTo: { required, TimeValidate (value) {
        return DateFormater.formatDateTimeWithoutSlash(value) <= laterDate &&
        DateFormater.formatDateTimeWithoutSlash(value) >= previousDate
      }, RangeValidate (value) {
        if (DateFrom != 'Invalid date') {
          return DateFormater.formatDateTimeWithoutSlash(value) > DateFrom
        }else{
          return true
        }
      } 
    },
    formAsignMaquina: {
      Status:{required},
    },
  }
}